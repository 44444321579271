import { css } from '@emotion/react';
import { Paper, Typography, Chip, Avatar } from '@mui/material';
import map from 'lodash/map';
import React, { memo, useMemo } from 'react';
import { ComposedChart } from 'recharts';
import { Bar, Cell, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts-new';
import { useTryCalcPositionState, } from '~/pages/futuresai/opbs/containers/useTryCalcPositionState';
import { representationAsNTD } from '~/utils/representationAsNTD';
import useMedia from '~/hooks/useMedia';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { globalBlue, globalGreen, globalGrey, globalRed, WHITE, globalYellow, } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
import IntradayTrendChart from '~/modules/trendChart';
import { useThemeStore } from '~/components/theme/useThemeStore';
/**
 * A magic number to adjust the length of bars in ratio between in trade-value and lot-count for
 * displaying nice, let's divide 5000, if data is needed for showing tooltip, multiply back.
 */
const tradeValueForAlignXAxis = 5000;
/** 選擇權分析之即時圖表 ＿買賣即時價格、口數 */
const OpbsHorizontalBarChart = memo(function OpbsHorizontalBarChart(props) {
    const theme = useThemeStore(s => s.theme);
    const { isPhone } = useMedia();
    const { acts: positionActs } = useTryCalcPositionState.useContainer();
    const data = useMemo(() => {
        return map(props.data, item => {
            const datum = {
                tradeValue: item.tradeValue / tradeValueForAlignXAxis,
                lotCount: item.lot,
                name: item.name,
                symbol: item.symbol,
                avgPrice: item.avgPrice,
                close: item.close,
                currentRevenue: (item.close - item.avgPrice) * item.lot * 50,
            };
            return datum;
        });
    }, [props.data]);
    const lotCountKey = 'lotCount';
    const tradeValueKey = 'tradeValue';
    const currentRevenue = 'currentRevenue';
    const CustomizedTooltip = ({ payload }) => {
        // 只會顯示有合理化在圖上的欄位，所以拿 tradeValueKey 比對去拿 Datum 物件
        const oneColumn = payload?.find(p => p.dataKey === tradeValueKey);
        const datum = oneColumn?.payload;
        const adjustTradeValue = representationAsNTD(Number(datum?.tradeValue || 0) * tradeValueForAlignXAxis);
        const adjustRevenue = representationAsNTD(Number(datum?.currentRevenue || 0));
        const toolTipContainerCss = css `
      ${flex.v.default};
      padding: 8px 16px 16px;
      width: 120px;
      & > * {
        margin-top: 8px;
      }
    `;
        return (<Paper css={isPhone ? flex.v.default : flex.h.default}>
        <div css={toolTipContainerCss}>
          <Typography variant='subtitle1'>{datum?.name}</Typography>
          <Typography variant='caption' css={css `
              color: ${datum?.tradeValue && datum?.tradeValue > 0
                ? globalRed.r200
                : globalGreen.g200};
              ${fontWeight600};
            `}>
            {/* representationAsNTD會除以2 */}
            {adjustTradeValue}
          </Typography>
          <Typography variant='caption' css={css `
              color: ${datum?.lotCount && datum?.lotCount > 0 ? globalRed.r700 : globalGreen.g500};
              ${fontWeight600};
            `}>
            {datum?.lotCount + '口'}
          </Typography>
          <Typography variant='subtitle2'>持倉損益：</Typography>
          <Typography variant='caption' css={css `
              color: ${datum?.currentRevenue && datum?.currentRevenue > 0
                ? globalRed.r700
                : globalGreen.g500};
              ${fontWeight600};
            `}>
            {adjustRevenue}
          </Typography>
          <CustomizedChip priceColor={globalYellow.y900} iconText='現' data={datum?.close ? datum?.close : '-'}/>
          <CustomizedChip priceColor={globalBlue.b900} iconText='均' data={datum?.avgPrice ? datum?.avgPrice : '-'}/>
        </div>
        {datum?.symbol && (<div css={css `
              width: ${isPhone ? 150 : 200}px;
              height: ${isPhone ? 150 : 200}px;
            `}>
            <IntradayTrendChart symbol={datum?.symbol} ticksSize={12} ticksHeight={16} transparency={0.3} priceTicksMargin={-20} priceTicksSize={10} intraday={true}/>
          </div>)}
      </Paper>);
    };
    const defaultLotTicks = [-400, 0, 400];
    const range = [-props.maxTick, props.maxTick];
    const revRange = [-props.maxRev, props.maxRev];
    const CustomizedYAxisLabel = (tickProps, mirror, atTheMoneyPrice) => {
        const { x, y, payload } = tickProps;
        const adjustmentMargin = -52;
        const nowAdjust = isPhone ? -0 : 0;
        const avgAdjust = isPhone ? 32 : 48;
        const nameAdjust = isPhone ? 60 : 84;
        const fontSize = isPhone ? 14 : 16;
        const anchor = mirror ? 'end' : 'start';
        const PriceName = () => (<_CustomizedSvgText x={x + (mirror ? -(adjustmentMargin + nameAdjust) : adjustmentMargin + nameAdjust)} y={y + 6} fontSize={fontSize} fill={theme === 'dark' ? 'white' : 'black'} textAnchor={anchor} content={data[payload.index].name}/>);
        const Avg = () => (<_CustomizedSvgText x={x + (mirror ? -(adjustmentMargin + avgAdjust) : adjustmentMargin + avgAdjust)} y={y + 6} fontSize={fontSize} fill={globalBlue.b900} textAnchor={anchor} content={data[payload.index].avgPrice ? data[payload.index].avgPrice : '-'}/>);
        const Now = () => (<_CustomizedSvgText x={x + (mirror ? -(adjustmentMargin + nowAdjust) : adjustmentMargin + nowAdjust)} y={y + 6} fontSize={fontSize} fontWeight={400} fill={globalYellow.y900} textAnchor={anchor} content={data[payload.index].close ? data[payload.index].close : '-'}/>);
        const Highlight = () => (<rect x={0} y={y - 14} height={28} width={'100%'} css={css `
          fill: ${globalGrey.g600}33;
        `}/>);
        return (<svg css={css `
          cursor: pointer;
        `}>
        <>
          {atTheMoneyPrice === parseInt(data[payload.index].name) && <Highlight />}
          <PriceName />
          <Now />
          <Avg />
        </>
      </svg>);
    };
    return (<ResponsiveContainer>
      <ComposedChart data={data} layout={'vertical'} stackOffset='expand'>
        <Legend verticalAlign='top' layout='vertical' content={() => props.title}/>

        {/* 不要用[ticks].map(t=><ReferenceLine/>)去跑 */}
        <ReferenceLine x={2000} xAxisId={tradeValueKey} stroke={globalGrey.g300}/>
        <ReferenceLine x={-2000} xAxisId={tradeValueKey} stroke={globalGrey.g300}/>

        <Bar dataKey={tradeValueKey} xAxisId={tradeValueKey}>
          {data.map((entry, index) => (<Cell key={index} fill={entry.tradeValue >= 0 ? globalRed.r200 : globalGreen.g100}/>))}
        </Bar>

        <Bar dataKey={lotCountKey} xAxisId={tradeValueKey} minPointSize={1}>
          {data.map((entry, index) => (<Cell key={index} fill={entry.lotCount >= 0 ? globalRed.r700 : globalGreen.g500}/>))}
        </Bar>
        <ReferenceLine x={0} xAxisId={tradeValueKey} stroke={isPhone ? globalGrey.g400 : globalGrey.g700}/>
        <Tooltip content={<CustomizedTooltip />}/>
        <Line type='monotone' dataKey={currentRevenue} xAxisId={currentRevenue} strokeWidth={isPhone ? 1 : 2} stroke={globalGrey.g400} activeDot={{ r: 8 }}/>
        <XAxis interval='preserveStartEnd' domain={range} type='number' tickLine={false} ticks={defaultLotTicks.map(a => a * 10)} xAxisId={tradeValueKey}/>

        <XAxis hide={true} domain={revRange} type='number' xAxisId={currentRevenue}/>
        <YAxis type='category' tickLine={false} onClick={(event) => {
            positionActs.addPosition(data[event.index]);
        }} axisLine={false} orientation={props.mirrorY ? 'right' : 'left'} tick={p => CustomizedYAxisLabel(p, props.mirrorY ?? false, props.atTheMoneyPrice)} scale='band'/>
      </ComposedChart>
    </ResponsiveContainer>);
});
const CustomizedChip = memo(function CustomizedChip({ priceColor, data, iconText }) {
    return (<Chip variant='outlined' size='small' avatar={<Avatar css={css `
            color: ${priceColor};
            background: ${WHITE};
          `}>
          {iconText}
        </Avatar>} label={data} css={css `
        background: ${priceColor};
        color: ${WHITE};
        font-size: 15px;
        ${fontWeight600};
        border: 1px solid ${priceColor};
      `}/>);
});
const _CustomizedSvgText = memo(function _CustomizedSvgText({ x, y, textAnchor, content, fill, fontSize, fontWeight }) {
    const { isPhone } = useMedia();
    return (<text fontWeight={fontWeight ?? 900} fontSize={fontSize ? fontSize : isPhone ? 14 : 16} x={x} y={y} fill={fill} textAnchor={textAnchor}>
      {content}
    </text>);
});
export default OpbsHorizontalBarChart;
